import { app as api } from '@ekuaibao/whispered';
export default [
    {
        id: '@reconciliation-setting',
        path: '/reconciliation-setting',
        dependencies: ['@supplier-setting'],
        ref: '/',
        onload: function () { return import('./ReconciliationSettingView'); },
        store: function () { return import('./reconciliation-setting.store'); },
        'get:reconciliation:config': function (cb) {
            return api.store.dispatch('@reconciliation-setting/getReconciliationConfig')(cb);
        }
    },
    {
        point: '@@menus',
        onload: function () { return [
            {
                id: 'reconciliation-setting',
                pId: 'reconciliationManage',
                permissions: ['SYS_ADMIN'],
                weight: 10,
                label: '',
                href: '/reconciliation-setting'
            }
        ]; }
    },
    {
        point: '@@layers',
        prefix: '@reconciliation-setting',
        onload: function () { return import('./layers'); }
    }
];
